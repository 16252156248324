/** @styleguide-autodiscovery-ignore-start */
import { ErrorResponse } from 'apollo-link-error';

// @ts-ignore
import * as zen from 'z-frontend-zen-js';

import createApolloClientBase, {
  getApolloProvider,
  setRedirectToLoginOnAuthError,
  ApolloClientOptions,
} from './src/createApolloClient';
import { getEventLogger } from './src/event-logger';
import fetchWrapper from './src/fetchWrapper';

import { SubscriptionInfoQuery } from './src/gqlTypes';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

type CustomGraphqlError = {
  isNetworkError?: boolean;
  status?: number;
  statusText?: string;
  message?: string;
  path?: (string | number)[];
};

// add error logging with eventLogger by default for Graphql errors
export const createApolloClient = (options: ApolloClientOptions) =>
  createApolloClientBase({
    ...options,
    fetch: fetchWrapper,
    onGraphqlError(error: ErrorResponse) {
      if (error.networkError) {
        getEventLogger().logError(new Error(`[Graphql network error]: ${error.networkError}`));
      }
      if (error.graphQLErrors && error.graphQLErrors.length) {
        error.graphQLErrors.forEach(error => {
          // Errors are being reformatted in zgrapqhl repo, so the type does not match default apollo types
          const { message, path, isNetworkError, status, statusText } = (error as any) as CustomGraphqlError;

          if (message) {
            const errorForEventLogger = new Error(`[GraphQL error]: Message: ${message}, Path: ${path}`);
            getEventLogger().logError(errorForEventLogger);
          } else if (isNetworkError) {
            // Logging errors for users who aren't logged in is too noisy
            if (status !== 401) {
              getEventLogger().logError(
                new Error(
                  `A graphql request received a network error within resolver.  Status: ${status}, Status text: ${statusText}`,
                ),
              );
            }
          } else {
            getEventLogger().logError(new Error('A graphql error was received with no expected context.'));
          }
        });
      }

      if (options.onGraphqlError) {
        return options.onGraphqlError(error);
      }
    },
  });

export { getApolloProvider, setRedirectToLoginOnAuthError };
export { default as bootFullstory, shutdownFullStory, FullStoryControlLevel } from './src/fullstory/boot-fullstory';
export { createReduxIntlDecorator, createReduxProvider, createReduxDecorator } from './src/createReduxDecorator';
export { getEventLogger, ApolloClientOptions, ErrorResponse as GraphqlErrorResponse };
export { ReduxProviderFactoryOptions } from './src/createReduxProvider';
export { default as createApolloDecorator } from './src/createApolloDecorator';
export { default as createRouterProvider } from './src/createRouterProvider';
export {
  default as createIntlProvider,
  createLocaleReducer,
  IntlContext,
  useFormatMessage,
  getFormattedMessage,
} from './src/createIntlProvider';
export { default as getBrowserLocale } from './src/getBrowserLocale';
export { default as sessionTimer, useUserAuthenticationState, UserAuthenticationState } from './src/session-timer';
export { default as renderBaseApp, AppSettings } from './src/renderApp';
export { generateUUID } from './src/uuid';
export { default as fetchWrapper } from './src/fetchWrapper';
export { default as getCookie } from './src/utils/get-cookie';
export { default as getDefaultHeaders } from './src/utils/get-default-headers';
export { default as logout } from './src/utils/log-out';
export { default as detectMobileOS, isAndroidMobile, isIPhone } from './src/utils/detectMobileOS';
export { addDelayToResolvers, getDelay, setDelay, timeoutPromise } from './src/utils/mockDelay';
export { setViewports, addScreenshotDelay, skipVisualTest } from './src/utils/storybookUtil';
export { default as throwInDevelopment } from './src/utils/throwInDevelopment';
export { getApollo, setApolloClient, resetApolloClientForTesting } from './src/getApollo';
export { captcha, CaptchaStatus } from './src/arkose/boot-arkose';
export { getStore, setStore } from './src/getStore';
export { default as AppInit } from './src/app-init/AppInit';
export { useDocumentTitle } from './src/title/useDocumentTitle';
export { sendActionToEmber, subscribeToEmberMessage } from './src/emberIntegration';
export { default as pendoHelpers } from './src/utils/pendoHelpers';
export { ApolloClient, ApolloQueryResult } from 'apollo-client';
export { initializePerformanceAnalytics } from './src/analyticsInitializer';
export { BoomerangOptions } from './src/boomerang';
export { toggleSolvvy, useSolvvy, SOLVVY_SUPPORT_FLOW } from './src/boot-solvvy';
export { toggleNiceWidget } from './src/boot-nice';
export { default as buildEmberRouteRedirect, QueryParamsProps } from './src/utils/buildEmberRouteRedirect';
export { default as useRefreshPrerequisites } from './src/prerequisite/useRefreshPrerequisites';
export { PrerequisiteRedirect, paywallRedirectGroupAllowList } from './src/prerequisite/prerequisiteRedirectUtil';
export { prerequisiteRedirectAllowlist } from './src/prerequisite/prerequisiteRedirectAllowlist';
export { default as useMarkPrerequisiteTaskComplete } from './src/prerequisite/useMarkTaskComplete';
export { default as switchEmployee } from './src/utils/switchEmployee';
type SubscriptionInfo = SubscriptionInfoQuery.ActiveBillingDetails;
type OptionalProduct = SubscriptionInfoQuery.OptionalProducts;
export { SubscriptionInfo, OptionalProduct };
/** @styleguide-autodiscovery-ignore-end */

export { default as SectionRoute } from './src/route/SectionRoute';
export { default as ErrorBoundary, ErrorBoundaryProps, withErrorBoundary } from './src/ErrorBoundary';
export { zen };

export { default as loadPolyfillsIfNeeded } from './src/polyfills/polyfill-helper';

export { default as sanitizeHtml } from './src/utils/sanitizeHtml';

export { default as useRedirectIfNotLoggedIn } from './src/useRedirectIfNotLoggedIn';

export { default as getSubscriptionInfo, SubscriptionInfoWithIds } from './src/storeSubscriptionInfo';

export { SegmentProvider } from './src/segment/SegmentManager';
export { default as useSegmentTraits } from './src/segment/useSegmentTraits';

export { BRAND_NAME, SWITCHES } from './src/constants';

export { default as FeaturesProvider } from './src/FeaturesProvider';
